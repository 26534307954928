<template>
  <div>
    <el-dialog title="添加系统外农机" :visible="dialogVisible" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="农机类型" prop="machineType">
          <div class="flex-row-dia">
            <el-select
              v-model="form.machineType"
              placeholder="请选择"
              clearable
              @change="
                (val) => {
                  typeSelectChange(val);
                }
              "
            >
              <el-option v-for="item in machineTypes" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="联系人" prop="contractName">
          <el-input placeholder="请输入联系人" style="width: 200px; margin-right: 4px" v-model="form.contractName"> </el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="contractPhone">
          <el-input placeholder="请输入联系电话" maxlength="11" style="width: 200px; margin-right: 4px" v-model="form.contractPhone"> </el-input>
        </el-form-item>

        <el-form-item label="亩数" prop="workArea">
          <el-input placeholder="请输入亩数" style="width: 200px; margin-right: 4px" v-model="form.workArea"> </el-input>亩
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="closeDg()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: false
  },
  components: {},
  computed: {},
  data() {
    return {
      rules: {
        machineType: [{ required: true, message: '请选择农机类型', trigger: 'change' }],
        contractName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        workArea: [{ required: true, message: '请输入亩数', trigger: 'blur' }],
        contractPhone: [
          {
            required: true,
            message: '请输入正确的联系电话',
            validator: this.validatePhone,
            trigger: 'blur'
          }
        ]
      },
      form: {
        machineType: '',
        contractName: '',
        contractPhone: '',
        workArea: '',
        descriptions: ''
      },
      machineTypeObj: '',
      machineTypes: []
    };
  },
  created() {
    this.getDicList();
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.form = {
          machineType: '',
          contractName: '',
          contractPhone: '',
          workArea: '',
          descriptions: ''
        };
      }
    }
  },
  methods: {
    // 校验手机号
    validatePhone(rule, value, callback) {
      let msg = '';
      value = value.toString().replace(/\s+/g, '');
      if (!value) {
        msg = '请输入手机号码！';
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(value)) {
          msg = '请输入正确的手机号码！';
        }
      }
      if (msg) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
    async getDicList() {
      let key = 'machine.type';
      this.machineTypes = await this.$store.dispatch('getDicOptionsList', key);
    },
    numInputChange(val, row) {
      let meansList = [];
      if (!val || val < 0) {
        val = 1;
      } else if (val > row.availableAmount) {
        val = row.availableAmount;
      }
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.meansNum = parseInt(val);
          item.totalMeansVal = parseInt(val) * item.countPerUnit;
        }
      });
    },
    submitMeansSelect() {
      this.meansDgVisible = false;
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.form['id'] = +new Date();
          this.$emit('updateOutList', this.form);
          this.$emit('update:dialogVisible', false);
        }
      });
    },
    typeSelectChange(val) {
      let obj = null;
      this.machineTypes.forEach((item) => {
        if (item.id == val) {
          obj = item;
        }
      });
      this.form['machineTypeName'] = obj.name;
    },
    closeDg() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
.select-means-wrap {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  .means-item {
    display: flex;
    align-items: center;
    color: #313746;
    background: #f7f8fa;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    .selected-means-del {
      color: #409eff;
      cursor: pointer;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .row1,
    .row2 {
      width: 50%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  // span {
  //   width: 50%;
  // }
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
