<template>
  <div>
    <el-dialog title="记农事" :visible="dialogVisible" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="农事名称" prop="farmingTypeId">
          <div class="flex-row-dia">
            <el-select v-model="form.farmingStage" placeholder="请选择农事节点" @change="stageChange">
              <el-option v-for="item in farmingTypes" :key="item.stageCode" :label="item.stageName" :value="item.stageCode"> </el-option>
            </el-select>
            <el-select v-model="form.farmingTypeId" placeholder="请选择农事名称">
              <el-option v-for="item in farmingTypeNames" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </el-form-item>

        <!-- <el-form-item label="操作人" prop="userId">
          <el-select v-model="form.userId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="地块名称" prop="landId">
          <el-select v-model="form.landId" placeholder="请选择地块" @change="landChange">
            <el-option v-for="item in plantLands" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="种植面积" prop="plantArea">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="form.plantArea" :disabled="true"> </el-input>亩
        </el-form-item>
        <el-form-item label="作业面积" prop="completeArea">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" @change="completeAreaInputChange" v-model="form.completeArea">
          </el-input
          >亩
        </el-form-item>
        <el-form-item label="参与人" prop="executorName">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="form.executorName"> </el-input>
        </el-form-item>
        <el-form-item label="农事时间" prop="farmDate">
          <el-date-picker v-model="farmDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <template v-if="form.farmingTypeId == 31 || form.farmingTypeId == 42">
          <el-form-item label="作物类型" prop="cropsACategoryId">
            <el-select v-model="form.cropsACategoryId" placeholder="请选择作物类型" @change="cropsACategoryChange">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作物品种" prop="cropsAId">
            <el-select v-model="form.cropsAId" placeholder="请选择作物品种">
              <el-option v-for="item in speciesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="种植密度" prop="plantingDensity">
            <el-input placeholder="请输入种植密度" style="width: 200px; margin-right: 4px" v-model="form.plantingDensity"> </el-input>
          </el-form-item>
        </template>
        <template v-if="form.farmingTypeId == 62">
          <el-form-item label="用水量" prop="irrigationValue">
            <el-input placeholder="请输入用水量" style="width: 200px; margin-right: 4px" v-model="form.irrigationValue"> </el-input>吨
          </el-form-item>
        </template>
        <template v-if="form.farmingTypeId == 34">
          <el-form-item label="亩产" prop="actualYield">
            <el-input placeholder="请输入亩产" style="width: 200px; margin-right: 4px" v-model="form.actualYield"> </el-input>斤 (含水量20%)
          </el-form-item>
          <el-form-item label="总量">
            <el-input placeholder="请输入每亩产量" style="width: 200px; margin-right: 4px" v-model="totalYield" disabled> </el-input>斤
          </el-form-item>
        </template>

        <el-form-item label="备注">
          <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
        </el-form-item>

        <el-form-item label="上传照片">
          <el-upload
            :class="{ hideUpload: hideUploadEdit }"
            action=""
            ref="upload"
            :file-list="fileList"
            :limit="1"
            list-type="picture-card"
            accept="image/jpg,image/jpeg,image/png"
            :auto-upload="false"
            :on-change="fileChange"
            :on-remove="fileChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="添加农资">
          <el-button @click="addMeans()">+</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="select-means-wrap">
            <template v-for="item in meansList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row">
                    <span>农资：{{ item.name }}</span>
                    <span>规格：{{ item.countPerUnit }}{{ unitSpecs[item.unitSpecs] }}/{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                  </div>
                  <div class="row">
                    <span>数量：{{ item.meansNum }}{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                    <span>总量：{{ item.totalMeansVal }}{{ unitSpecs[item.unitSpecs] }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="meansSelectChange(false, item)">删除</div>
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="添加农机">
          <el-button @click="addMachines()">+</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="select-means-wrap">
            <template v-for="item in inMachineList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row">
                    <span>类型：{{ item.machineTypeName || '-' }}</span>
                    <span>车牌号：{{ item.licensePlateNumber || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>内部编号：{{ item.code || '-' }}</span>
                    <span>农机归属：{{ item.machineBelongTypeName || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>厂商：{{ item.factoryName || '-' }}</span>
                    <span>亩数（亩）：{{ item.workArea || '-' }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="inMachineSelectChange(false, item)">删除</div>
              </div>
            </template>
            <template v-for="item in outMachineList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row">
                    <span>类型：{{ item.machineTypeName || '-' }}</span>
                    <span>联系人：{{ item.contractName || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>联系电话：{{ item.contractPhone || '-' }}</span>
                    <span>亩数（亩）：{{ item.workArea || '-' }}</span>
                  </div>
                  <div class="row">
                    <span>备注：{{ item.descriptions || '-' }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="outMachineSelectChange(false, item)">删除</div>
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
          <el-button @click="closeDg()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="农资列表" :visible="meansDgVisible" width="60%" @close="closeMeansDg" destroy-on-close :close-on-click-modal="false">
      <el-table v-if="meansList.length" ref="multipleTable" :data="meansList" stripe>
        <el-table-column label="选择状态" width="100">
          <template slot-scope="scope">
            <el-checkbox
              @change="
                (val) => {
                  meansSelectChange(val, scope.row);
                }
              "
              v-model="scope.row.selected"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="农资"> </el-table-column>
        <el-table-column prop="agricultureResourceTypeName" label="类型"> </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.countPerUnit }}{{ unitSpecs[scope.row.unitSpecs] }}/{{ unitMeasurement[scope.row.unitOfMeasurement] }}
          </template>
        </el-table-column>
        <el-table-column label="库存数">
          <template slot-scope="scope">
            {{ scope.row.availableAmount }}
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input
              @change="
                (val) => {
                  numInputChange(val, scope.row);
                }
              "
              type="number"
              min="1"
              v-model="scope.row.meansNum"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="总量">
          <template slot-scope="scope"> {{ scope.row.totalMeansVal }}{{ unitSpecs[scope.row.unitSpecs] }} </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeMeansDg">取 消</el-button>
        <el-button type="primary" @click="submitMeansSelect">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="农机列表" :visible="machineDgVisible" width="60%" @close="closeMachineDg" destroy-on-close :close-on-click-modal="false">
      <el-tabs v-model="machineCategory">
        <el-tab-pane label="系统内农机" name="1"></el-tab-pane>
        <el-tab-pane label="系统外农机" name="2"></el-tab-pane>
      </el-tabs>
      <el-table v-if="inMachineList.length && machineCategory == 1" ref="multipleTable" :data="inMachineList" stripe>
        <el-table-column label="选择状态" width="100">
          <template slot-scope="scope">
            <el-checkbox
              @change="
                (val) => {
                  inMachineSelectChange(val, scope.row);
                }
              "
              v-model="scope.row.selected"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="machineTypeName" label="类型">
          <template slot-scope="scope">
            {{ scope.row.machineTypeName || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="licensePlateNumber" label="车牌号">
          <template slot-scope="scope">
            {{ scope.row.licensePlateNumber || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="code" label="内部编码">
          <template slot-scope="scope">
            {{ scope.row.code || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="machineBelongTypeName" label="农机归属">
          <template slot-scope="scope">
            {{ scope.row.machineBelongTypeName || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="factoryName" label="厂商名称">
          <template slot-scope="scope">
            {{ scope.row.factoryName || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="亩数（亩）">
          <template slot-scope="scope">
            <el-input
              type="number"
              placeholder="亩数"
              @change="
                (val) => {
                  inMachineAreaChange(val, scope.row);
                }
              "
              v-model="scope.row.workArea"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <template v-if="machineCategory == 2">
        <el-row type="flex" justify="end" style="margin-bottom: 12px">
          <el-button type="primary" size="small" @click="createSysOutMachineFlag = true">添加系统外农机</el-button>
        </el-row>
        <el-table ref="multipleTable" :data="outMachineList" stripe>
          <el-table-column label="选择状态" width="100">
            <template slot-scope="scope">
              <el-checkbox
                @change="
                  (val) => {
                    outMachineSelectChange(val, scope.row);
                  }
                "
                v-model="scope.row.selected"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="machineTypeName" label="农机类型"> </el-table-column>
          <el-table-column prop="contractName" label="联系人"> </el-table-column>
          <el-table-column prop="contractPhone" label="联系电话"> </el-table-column>
          <el-table-column prop="descriptions" label="备注">
            <template slot-scope="scope">
              {{ scope.row.descriptions ? scope.row.descriptions : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="workArea" label="亩数（亩）"> </el-table-column>
        </el-table>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeMachineDg">取 消</el-button>
        <el-button type="primary" @click="submitMachineSelect">确 定</el-button>
      </div>
    </el-dialog>
    <CreateSysOutMachine :dialogVisible.sync="createSysOutMachineFlag" @updateOutList="updateSysOutList"></CreateSysOutMachine>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { productionReport, createWorkFarming, getRequest } from '@/api/api.js';
import CreateSysOutMachine from './createSysOutMachine.vue';
export default {
  props: {
    dialogVisible: false,
    isEdit: true,
    departmentList: [],
    // sysUsers: [],
    farmingTypes: [],
    farmingNameList: [],
    plantLands: []
  },
  components: { CreateSysOutMachine },
  computed: {},
  data() {
    return {
      hideUploadEdit: false,
      rules: {
        farmingTypeId: [{ required: true, message: '请选择农事名称', trigger: 'change' }],
        landId: [{ required: true, message: '请选择地块名称', trigger: 'change' }],
        plantArea: [{ required: true, message: '请输入种植面积', trigger: 'blur' }],
        completeArea: [{ required: true, message: '请输入作业面积', trigger: 'blur' }],
        executorName: [{ required: true, message: '请输入参与人', trigger: 'blur' }],
        cropsAId: [{ required: true, message: '请选择作物品种', trigger: 'change' }],
        plantingDensity: [{ required: true, message: '请输入种植密度', trigger: 'blur' }],
        irrigationValue: [{ required: true, message: '请输入用水量', trigger: 'blur' }],
        actualYield: [{ required: true, message: '请输入亩产', trigger: 'blur' }],
        farmDate: [
          {
            validator: this.validateFarmDate,
            required: true
          }
        ]
      },
      form: {
        farmingTypeId: '',
        landId: '',
        plantArea: 0,
        completeArea: '',
        executorName: '',
        cropsAId: '',
        plantingDensity: '',
        irrigationValue: '',
        actualYield: '',
        descriptions: ''
      },
      fileList: [],
      farmDate: '',
      meansPageInfo: {
        currentPage: 1,
        pageSize: 99999,
        totalPage: 0
      },
      meansList: [],
      outMachineList: [],
      inMachineList: [],
      selectIds: [],
      meansDgVisible: false,
      machineDgVisible: false,
      unitSpecs: {},
      unitMeasurement: {},
      selectMeans: [],
      farmingTypeNames: [],
      startDate: '',
      machineCategory: '1',
      createSysOutMachineFlag: false,
      categoryList: [],
      speciesList: []
      // totalYield: 0
    };
  },
  created() {
    this.getDicList();
    this.getCategoryList();
  },
  computed: {
    totalYield() {
      return Number((this.form.actualYield || 0) * (this.form.completeArea || 0)).toFixed(2);
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.form = {
          farmingTypeId: '',
          landId: '',
          plantArea: 0,
          completeArea: '',
          executorName: '',
          cropsAId: '',
          plantingDensity: '',
          irrigationValue: '',
          actualYield: '',
          descriptions: ''
        };
        this.hideUploadEdit = false;
        this.fileList = [];
        this.farmDate = [];
        this.meansList.forEach((item) => {
          item.selected = false;
          item.meansNum = 1;
          item.totalMeansVal = item.countPerUnit;
        });
        this.inMachineList.forEach((item) => {
          item.selected = false;
          item.workArea = 1;
        });
        this.outMachineList = [];
      }
    }
  },
  methods: {
    completeAreaInputChange(val) {
      if (val > this.form.plantArea * 1.05) {
        this.form.completeArea = this.form.plantArea;
      }
    },
    getCategoryList() {
      this.categoryList = [];
      getRequest('/api/operate/planting/scheme/category/list', {})
        .then((res) => {
          this.categoryList = res;
        })
        .catch((error) => {});
    },
    stageChange(val) {
      this.farmingNameList.map((item) => {
        if (item.stageCode == val) {
          this.farmingTypeNames = item.farmingTypes;
        }
      });
      // this.farmingTypeNames = this.farmingNameList[val];
      this.form.farmingTypeId = '';
    },
    cropsACategoryChange(id) {
      this.form.cropsAId = '';
      this.categoryList.map((e) => {
        if (id == e.id) {
          this.speciesList = e.species || [];
        }
      });
    },
    landChange(val) {
      this.form.landId = val;
      this.getArea();
    },
    // userChange(val) {
    //   this.form.userId = val;
    // },
    getArea() {
      this.plantLands.forEach((item) => {
        if (item.id == this.form.landId) {
          this.form.plantArea = item.plantArea;
          this.form.completeArea = item.plantArea;
          return;
        }
      });
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    editMeansList(rows) {
      let list = [];
      rows.forEach((item) => {
        if (item.availableAmount && item.availableAmount > 0) {
          item.meansNum = 1;
          item.totalMeansVal = item.meansNum * item.countPerUnit;
          list.push(item);
        }
      });
      return list;
    },
    addMeans(reLoad) {
      if (!reLoad && this.meansList.length) {
        this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
        this.meansDgVisible = true;
        return;
      }
      productionReport({
        pageNum: this.meansPageInfo.currentPage,
        pageSize: this.meansPageInfo.pageSize
      })
        .then((res) => {
          this.meansList = this.editMeansList(res.rows);
          this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
          this.meansDgVisible = true;
          this.meansPageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    addMachines() {
      if (this.inMachineList.length) {
        this.tempInMachineList = JSON.parse(JSON.stringify(this.inMachineList));
        this.machineDgVisible = true;
        return;
      }
      getRequest('/api/operate/planting/history/farming/machine/page', {
        pageNum: 1,
        pageSize: 999
      })
        .then((res) => {
          this.inMachineList = this.editMachineList(res.rows);
          this.tempInMachineList = JSON.parse(JSON.stringify(this.inMachineList));
          this.machineDgVisible = true;
        })
        .catch((error) => {});
    },
    editMachineList(rows) {
      let list = [];
      rows.forEach((item) => {
        item.workArea = 1;
        list.push(item);
      });
      return list;
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');
      this.unitSpecs = res['unit.specs'];
      this.unitMeasurement = res['unit.of.measurement'];
    },
    numInputChange(val, row) {
      let meansList = [];
      if (!val || val < 0) {
        val = 1;
      } else if (val > row.availableAmount) {
        val = row.availableAmount;
      }
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.meansNum = parseInt(val);
          item.totalMeansVal = parseInt(val) * item.countPerUnit;
        }
      });
    },
    inMachineAreaChange(val, row) {
      if (!val || val < 0) {
        val = 1;
      }
      this.inMachineList.forEach((item) => {
        if (item.id == row.id) {
          item.workArea = parseFloat(val);
        }
      });
    },
    meansSelectChange(val, row) {
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    inMachineSelectChange(val, row) {
      this.inMachineList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    outMachineSelectChange(val, row) {
      this.outMachineList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    handleSizeChange(e) {
      this.meansPageInfo.currentPage = e;
      this.addMeans(true);
    },
    submitMeansSelect() {
      this.tempMeansList = JSON.parse(JSON.stringify(this.meansList));
      this.meansDgVisible = false;
    },
    closeMeansDg() {
      this.meansList = JSON.parse(JSON.stringify(this.tempMeansList));
      this.meansDgVisible = false;
    },
    submitMachineSelect() {
      this.tempInMachineList = JSON.parse(JSON.stringify(this.inMachineList));
      this.machineDgVisible = false;
    },
    closeMachineDg() {
      this.inMachineList = JSON.parse(JSON.stringify(this.tempInMachineList));
      this.machineDgVisible = false;
    },
    getRowKey(row) {
      return row.id;
    },
    getSelectIds() {
      let useStocks = [];
      this.meansList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['productionId'] = item.id;
          selObj['amount'] = item.meansNum;
          useStocks.push(selObj);
        }
      });
      return useStocks;
    },
    getOutMachines() {
      let outMachines = [];
      this.outMachineList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['machineType'] = item.machineType;
          selObj['contractName'] = item.contractName;
          selObj['contractPhone'] = item.contractPhone;
          selObj['workArea'] = item.workArea;
          selObj['descriptions'] = item.descriptions;
          outMachines.push(selObj);
        }
      });
      return outMachines;
    },
    getUseMachines() {
      let useMachines = [];
      this.inMachineList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['machineId'] = item.id;
          selObj['workArea'] = item.workArea;
          useMachines.push(selObj);
        }
      });
      return useMachines;
    },
    validateFarmDate(rule, value, callback) {
      this.farmDate && this.farmDate.length ? callback() : callback(new Error(`请选择农事时间`));
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let useStocks = this.getSelectIds();
          let params = { ...this.form };
          params.useMachinesOfOutside = this.getOutMachines();
          params.useMachines = this.getUseMachines();
          if (this.farmDate.length) {
            params.startDate = this.farmDate[0] ? dayjs(this.farmDate[0]).format('YYYY-MM-DD') : '';
            params.endDate = this.farmDate[1] ? dayjs(this.farmDate[1]).format('YYYY-MM-DD') : '';
          }
          params.useStocks = useStocks;
          let formData = new FormData();
          if (this.fileList && this.fileList.length && this.fileList[0].raw) {
            formData.append('file', this.fileList[0].raw);
          }
          if (this.isLoading) {
            return;
          }
          this.isLoading = true;
          formData.append('requestJson', JSON.stringify({ ...params }));
          createWorkFarming(formData)
            .then((res) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeDg();
              this.updateList();
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    closeDg() {
      this.$emit('update:dialogVisible', false);
    },
    updateSysOutList(val) {
      this.outMachineList.push(val);
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
.select-means-wrap {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  .means-item {
    display: flex;
    align-items: center;
    color: #313746;
    background: #f7f8fa;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    .selected-means-del {
      color: #409eff;
      cursor: pointer;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .row {
      width: 50%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  // span {
  //   width: 50%;
  // }
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
