<template>
  <transition name="page">
    <div>
      仪表盘页面??
      <div>?</div>
      <quill-editor class="editor" :content="content" :options="editorOption" @change="onEditorChange" />
    </div>


  </transition>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    editorOption: {
      // Some Quill options...
    }
  },
  created() {
  },

  mounted() {

  },

  data() {
    return {
      content: ''
    }
  },

  methods: {
    onEditorChange(e) {
      console.log(e);
    },
    onEditorBlur() {
      console.log('blur', this.messages)
    },

    onEditorFocus() {
      console.log('focus', this.messages)
    },

    onEditorReady() {
      console.log('ready', this.messages)
    }

  }
}
</script>

<style scoped lang="scss">
.editor {
  width: 100%;
  height: 300px;
}
</style>
