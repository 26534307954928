<template>
  <div>
    <div class="bottom20">
      <el-row :gutter="20">
        <el-col :span="5" class="flex-row">
          <span class="span">种植作物</span>
          <el-select style="flex: 1" clearable v-model="categoryId" placeholder="请选择种植作物" @change="onCategoryChange">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5" class="flex-row">
          <span class="span">作物品种</span>
          <el-select style="flex: 1" clearable v-model="cropsId" placeholder="请选择作物品种" @change="onCropsChange">
            <el-option v-for="item in cropsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="flex-row">
          <span class="span">地块名称</span>
          <el-input placeholder="请输入地块名称" v-model="landName"></el-input>
        </el-col>
        <el-col :span="2" class="flex-row">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="landName" label="种植历史">
        <template slot-scope="scope"> {{ filterDate(scope.row.startDate) }} - {{ filterDate(scope.row.endDate) }} </template>
      </el-table-column>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <el-table-column prop="cropsACategoryName" label="种植作物"> </el-table-column>
      <el-table-column prop="cropsAName" label="作物品种"> </el-table-column>
      <el-table-column label="种植面积（亩）">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.plantingArea) }}</template>
      </el-table-column>
      <el-table-column label="收获日期">
        <template slot-scope="scope"> {{ filterDate(scope.row.harvestDate) }}</template>
      </el-table-column>
      <el-table-column label="亩产（斤）">
        <template slot-scope="scope"> {{ scope.row.actualYield }}</template>
      </el-table-column>
      <el-table-column label="总产量（斤）">
        <template slot-scope="scope"> {{ (scope.row.actualYield * scope.row.plantingArea).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="含水量">20%</el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { superviseHistory } from '@/api/cropYield/api.js';
import { categoryList, getRequest } from '@/api/api.js';
import * as utils from '@/utils/utils.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      searchWord: '',
      searchParams: {},
      categoryList: [],
      cropsList: [],
      categoryId: '',
      cropsId: '',
      landName: ''
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getCategoryList();
  },
  methods: {
    filterDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      } else {
        return '至今';
      }
    },
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    getCategoryList() {
      categoryList()
        .then((res) => {
          this.categoryList = res;
        })
        .catch((error) => {});
    },
    getList() {
      getRequest('/api/iot/conditions/growth/land/history', {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ...this.searchParams
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    search() {
      this.searchParams = {
        cropsACategoryId: this.categoryId,
        cropsAId: this.cropsId,
        landName: this.landName
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    onCategoryChange(id) {
      this.categoryId = id;
      this.cropsId = '';
      this.categoryList.forEach((e) => {
        if (id == e.id) {
          this.cropsList = e.species || [];
        }
      });
    },
    onCropsChange(id) {
      this.cropsId = id;
    }
  }
};
</script>
<style scoped lang="scss">
.bottom20 {
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 70px;
  }
}
</style>
